<template>
  <div class="organization-configuration">
    <div class="organization-configuration-container">
      <div class="organization-configuration-container-top">
        <button v-show="currentComponent === 'display'" class="organization-configuration-container-top-edit"
          @click="onEditClick">
          编辑
        </button>
      </div>
      <div class="organization-configuration-container-bottom">
        <component :is="currentComponent" :model="settingInfoModel" @onCancelClick="onEditCancelClick"
          @onSaveClick="onSaveClick"></component>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    Display: () => import("@c/setting/organization-configuration/display/display.vue"),
    Edit: () => import("@c/setting/organization-configuration/edit/edit.vue"),
  },

  data() {
    return {
      currentComponent: "display",
      rawSettingInfoModel: {},
      editedSettingInfoModel: {},
    };
  },

  computed: {
    hospitalID: function () {
      return this.$store.state.selectedHospital.hospitalid;
    },

    settingInfoModel() {
      const settingInfoModel = this.rawSettingInfoModel;
      if (!settingInfoModel.reportStandard) {
        //设置儿童标准默认类型
        settingInfoModel.reportStandard =
          CHILD_STANDDARD_TYPE_CODE["2020版中国指南/2014版美国指南"];
      }
      if (!settingInfoModel.reportEvaluate) {
        //设置报告评估默认类型
        settingInfoModel.reportEvaluate = REPORT_SUGGESTION_TYPE.智能评估;
      }
      return settingInfoModel;
    },
  },

  watch: {
    hospitalID: function () {
      this.currentComponent = "display";
      this.getInfo();
    },
  },

  created() {
 
    this.getInfo();
  },

  methods: {
    onEditClick() {
      if (this.currentComponent === "display") {
        this.currentComponent = "edit";
      } else {
        this.currentComponent = "display";
      }
    },

    onEditCancelClick() {
      this.currentComponent = "display";
    },

    onSaveClick(settingInfoModel) {
      this.editedSettingInfoModel = settingInfoModel;
      this.$emit("onSaveClick")
    },

    async getInfo() {
      try {
        const params = {
          "hospitalId": this.hospitalID
        };
        const data = await this.$api.getOrganizationConfig(params);
        this.rawSettingInfoModel = data;
        this.$store.state.selectedHospital.name = data.hospitalName
      } catch (error) {
        this.isRequestLocked = false;
        this.$toast.showRed(error);
      }
    },

    async getUserInfo() {
      try {
       
        const data = await this.$api.userInfo();
       
        if(this.hospitalID == data.hospitalId){
          
          this.$store.state.accountInfo.hospitalName = data.hospitalName
 
        }
      } catch (error) {
        
        this.$toast.showRed(error);
      }
    },

    async saveInfo() {
      try {
        let model = this.editedSettingInfoModel
        const params = {
          "hospitalId": this.hospitalID,
          "hospitalName": model.hospitalTitle,
          "hospitalLogo": model.hospitalLogo,
          "pollShowCaseNumber": model.pollShowCaseNumber
        };
        const _ = await this.$api.editOrganizationConfig(params);
        this.currentComponent = "display";
        this.$toast.showGreen("机构配置成功")
        this.getInfo();
        this.getUserInfo()
      } catch (error) {
        console.log(error);
        
        this.$toast.showRed(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>


.organization-configuration {
  background-color: white;
  border-radius: rem(2);
  width: 100%;
  height: 100%;

  &-container {
    height: 100%;
    overflow: hidden;
    // overflow-y: auto;
    box-sizing: border-box;
    padding: rem(0) rem(36) rem(50);

    &-top {
      width: 100%;
      height: rem(72);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &-edit {
        color: #325FAC;
        font-size: rem(14);
        border-radius: rem(4);
        border: rem(1) solid #325FAC;
        width: rem(88);
        height: rem(32);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-bottom {
      width: 100%;
    }
  }
}
</style>